.main {
  display: flex;
  flex-direction: column;
  row-gap: 3.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .main {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
